@import url("https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i");
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

html,
body {
  height: 100%;
}

.fx-layout-row {
  display: flex;
  flex-direction: row;
}

.fx-layout-column {
  display: flex;
  flex-direction: column;
}

.fx-layout-align-start-stretch {
  justify-content: start;
  align-items: stretch;
  align-content: stretch;
}

.fx-layout-align-center-center {
  justify-content: center;
  align-items: center;
  align-content: center;
}

.fx-layout-align-flex-start-center {
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.fx-layout-align-space-around-start {
  justify-content: space-around;
  align-items: start;
  align-content: start;
}

.fx-layout-align-space-between-center {
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.fx-layout-align-space-evenly-start {
  justify-content: space-evenly;
  align-items: start;
  align-content: start;
}

.fx-layout-align-center-start {
  justify-content: center;
  align-items: start;
  align-content: start;
}

.fx-layout-align-end-end {
  justify-content: flex-end;
  align-items: end;
  align-content: end;
}

.fx-flex-fill {
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
}

.fx-wrap {
  flex-wrap: wrap;
  flex-grow: 1;
}

.fx-flex {
  flex: 1 1 0px;
  box-sizing: border-box;
}

.fx-flex-auto {
  flex: 1 1 100%;
}

.fx-flex-1pc {
  flex: 1 1 1%;
  max-width: 1%;
}

.fx-flex-5pc {
  flex: 1 1 5%;
  max-width: 5%;
}

.fx-flex-28pc {
  flex: 1 1 28%;
  max-width: 28%;
}

.fx-flex-30pc {
  flex: 1 1 30%;
  max-width: 30%;
}

.fx-flex-40pc {
  flex: 1 1 40%;
  max-width: 40%;
}

.fx-flex-45pc {
  flex: 1 1 45%;
  max-width: 45%;
}

.fx-flex-59pc {
  flex: 1 1 59%;
  max-width: 59%;
}

.fx-flex-50pc {
  flex: 1 1 50%;
  max-width: 50%;
}

.fx-flex-60pc {
  flex: 1 1 60%;
  max-width: 60%;
}

.fx-flex-68pc {
  flex: 1 1 68%;
  max-width: 68%;
}

.fx-flex-80pc {
  flex: 1 1 80%;
  max-width: 80%;
}

.fx-flex-3rem {
  flex: 1 1 3rem;
}

.fx-flex-4rem {
  flex: 1 1 4rem;
}

:host ::ng-deep mat-tab-body {
  justify-content: center;
}
